import { load, PlainConfig } from "hybridconfig";

type AllowedKeys =
  | "metaUrl"
  | "aptRepoUrl"
  | "aptRepoSourceName"
  | "yumRepoUrl"
  | "yumRepoSourceName";

const config = load<AllowedKeys>({
  remoteConfigPath: "/config.json",
  envKeyPrefix: "APP_",
});

export type Config = PlainConfig<AllowedKeys>;

export default config;
