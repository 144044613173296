import { Release } from "./releases";

export const detectHost = (): Pick<Release, "platform" | "arch"> => {
  const userAgent = navigator.userAgent;
  if (userAgent.includes("Mac OS")) {
    if (userAgent.includes("Intel")) {
      return { platform: "darwin", arch: "x64" };
    } else {
      return { platform: "darwin", arch: "arm64" };
    }
  } else if (userAgent.includes("Windows")) {
    return { platform: "win32", arch: "x64" };
  } else if (userAgent.includes("Linux")) {
    return { platform: "linux", arch: "x64" };
  }
  // fallback: most common
  return { platform: "win32", arch: "x64" };
};
