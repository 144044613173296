import { detectHost } from "./detectHost";

export type OS = "linux" | "windows" | "macos";
export type Entry = {
  name: string;
  url: string;
  platform: string;
  arch: string;
};
export type Meta = {
  version: string;
  entries: Entry[];
};

export type Release = Entry & {
  os: OS;
};

export const fetchMeta = (url: string): Promise<Meta> =>
  fetch(url, {
    headers: {
      Accept: "application/json",
    },
  }).then((r) => r.json() as Promise<Meta>);

const platformToOs = (platform: string): OS => {
  if (platform.includes("linux")) return "linux";
  if (platform.includes("mac") || platform.includes("darwin")) return "macos";
  return "windows";
};

export const withAbsoluteUrl = (meta: Meta, baseUrl: string): Release[] =>
  meta.entries.map((v) => ({
    ...v,
    url: new URL(v.url, baseUrl).toString(),
    os: platformToOs(v.platform),
  }));

export const splitReleases = (
  releases: Release[]
): {
  hostLatestRelease?: Release;
  otherLatestReleases: Release[];
} => {
  const host = detectHost();
  const hostLatestRelease = releases.find(
    (x) => x.arch === host.arch && x.platform === host.platform
  );
  return {
    hostLatestRelease,
    otherLatestReleases: releases
      .filter((x) => x !== hostLatestRelease)
      .sort((a, b) => a.platform.localeCompare(b.platform)),
  };
};
