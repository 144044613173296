import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { UseRleasesStatus } from "../hooks/useReleases";
import { groupBy } from "../utils/groupBy";
import { Release } from "../utils/releases";
import { OSIcon } from "./OSIcon";

export const Downloads: React.FC<{ releases: UseRleasesStatus }> = ({
  releases,
}) => {
  if (releases.state === "loading") {
    return <CircularProgress />;
  } else if (
    releases.state === "error" ||
    (releases.data.hostLatestRelease === undefined &&
      releases.data.otherLatestReleases.length === 0)
  ) {
    return <Box component="p">No releases found.</Box>;
  }
  const { otherLatestReleases } = releases.data;

  return <Releases otherLatestReleases={otherLatestReleases} />;
};

const Releases: React.FC<{
  otherLatestReleases: Release[];
}> = React.memo(({ otherLatestReleases }) => {
  return (
    <Box width={"100%"}>
      <Typography variant="h6" gutterBottom>
        Also available on
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
      >
        {Object.entries(groupBy(otherLatestReleases, (x) => x.platform)).map(
          ([platform, rs]) => (
            <Stack direction="column" spacing={1} key={platform}>
              {rs.map((r) => (
                <Box key={r.name}>
                  <Button href={r.url} target="_blank">
                    <Box display="flex" alignItems="center">
                      <OSIcon os={r.os} />
                      <Box ml={1} />
                      {r.platform} {r.arch}(
                      {r.name.slice(r.name.lastIndexOf("."))})
                    </Box>
                  </Button>
                </Box>
              ))}
            </Stack>
          )
        )}
      </Stack>
    </Box>
  );
});
