import { useEffect, useState } from "react";
import {
  fetchMeta,
  Release,
  splitReleases,
  withAbsoluteUrl,
} from "../utils/releases";
import config from "../config";

export type UseRleasesStatus =
  | { state: "loading" }
  | { state: "error"; error: string }
  | {
      state: "data";
      data: {
        hostLatestRelease?: Release;
        otherLatestReleases: Release[];
      };
    };

export const useReleases = (): UseRleasesStatus => {
  const [status, setStatus] = useState<UseRleasesStatus>({ state: "loading" });

  useEffect(() => {
    const fn = async () => {
      try {
        const { metaUrl } = await config;
        const meta = await fetchMeta(metaUrl);
        if (meta && typeof meta === "object" && "entries" in meta) {
          setStatus({
            state: "data",
            data: splitReleases(withAbsoluteUrl(meta, metaUrl)),
          });
          return;
        }
        throw new Error("Invalid meta");
      } catch (e) {
        setStatus({ state: "error", error: String(e) });
      }
    };
    void fn();
  }, []);

  return status;
};
