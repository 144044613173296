export const aptInstructions = (url: string, name: string): string[] => {
  return [
    `sudo apt-get install apt-transport-https`,
    `echo "deb [trusted=yes] ${url} ./" | sudo tee /etc/apt/sources.list.d/humanode-${name}.list`,
    "sudo apt-get update",
    "sudo apt-get install humanode-desktop-app",
  ];
};

export const yumInstructions = (url: string, name: string): string[] => {
  return [
    `cat <<EOF | sudo tee /etc/yum.repo.d/humanode-${name}.repo`,
    `[humanode-${name}]`,
    `name=Humanode ${name}`,
    `baseurl=${url}`,
    `enabled=1`,
    `gpgcheck=0`,
    `EOF`,
    `sudo yum install humanode-desktop-app`,
  ];
};
