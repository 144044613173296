import {
  Box,
  Button,
  Card,
  Container,
  CssBaseline,
  Stack,
  Typography,
} from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/system";
import { Downloads } from "./components/Downloads";
import { OSIcon } from "./components/OSIcon";
import { SVGGradientProvider } from "./components/SVGGradientProvider";
import { useReleases } from "./hooks/useReleases";
import { Repositories } from "./Repositories";
import theme from "./theme";

function App(): JSX.Element {
  const releases = useReleases();

  return (
    <SVGGradientProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <img
              src="/assets/white-eyes-face.png"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                objectFit: "contain",
                zIndex: -1,
              }}
            ></img>
            <Container component="div" maxWidth="md">
              <Stack spacing={4} m={4}>
                <Card>
                  <Stack spacing={3} alignItems="center">
                    <img
                      src="/assets/humanode-logo.png"
                      alt="Humanode"
                      width={240}
                      height={240}
                    />
                    <Typography variant="h6">
                      Get the desktop app and run your node!
                    </Typography>
                    <Box alignSelf="center">
                      <Button
                        variant="contained"
                        href={
                          releases.state === "data" &&
                          releases.data.hostLatestRelease
                            ? releases.data.hostLatestRelease?.url
                            : ""
                        }
                        target="_blank"
                        disabled={
                          !(
                            releases.state === "data" &&
                            releases.data.hostLatestRelease
                          )
                        }
                      >
                        <Stack spacing={1} direction="row" alignItems="center">
                          {releases.state === "data" &&
                            releases.data.hostLatestRelease && (
                              <OSIcon os={releases.data.hostLatestRelease.os} />
                            )}
                          Download now
                        </Stack>
                      </Button>
                    </Box>
                    <Downloads releases={releases} />
                  </Stack>
                </Card>
                <Repositories />
              </Stack>
            </Container>
          </CssBaseline>
        </ThemeProvider>
      </StyledEngineProvider>
    </SVGGradientProvider>
  );
}

export default App;
